import theme from '../default/index.js';

/**
 * This is an override theme file for the EAU Nurses site
 */

import Color from 'color';

const primaryColor = Color('#284055'),
    secondaryColor = Color('#408FBB');

const color = {
    darkest: 'var(--color-secondary-dark)',
    lightest: '#fff',

    primary: primaryColor.hex(),
    'primary-light': primaryColor.lighten(0.15).hex(),
    'primary-dark': primaryColor.darken(0.25).hex(),
    secondary: secondaryColor.hex(),
    'secondary-light': '#f4f5f7',
    'secondary-dark': '#152443',
};

export default {
    ...theme,
    color
};
